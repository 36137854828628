@import "~bootstrap/scss/bootstrap";
@import "node_modules/react-modal-video/scss/modal-video.scss";
@import "../../public/assets/css/fontAwesome5Pro.css";
@import "../../public/assets/css/ionicons.min.css";
@import "~slick-carousel/slick/slick.css";
@import "react-toastify/dist/ReactToastify.css";
@import "../../public/assets/scss/main.scss";

.html {
  overflow-x: hidden !important;
}

body {
  overflow-x: hidden !important;
}

.product__wrapper__searchbar {
  width: 18%;
}

@media (max-width: 575px) {
  .product__wrapper__searchbar {
    width: 46%;
  }
}

@media (min-width: 576px) and (max-width: 991px) {
  .product__wrapper__searchbar {
    width: 30%;
  }
}

// .product__thumb__searchbar {
//   width: 100px !important;
// }

.product__thumb {
  width: 200px;
  height: 220px;
  border-radius: 4px;

  @media (max-width: 450px) {
    width: 100% !important;
    height: 170px !important;
  }

  @media (max-width: 365px) {
    width: 100% !important;
    height: 160px !important;
  }
}

@media (max-width: 575px) {
  .row {
    width: 100%;
    margin-left: 0;
  }
}

.shop_page_products_row {
  width: 25% !important;

  .product__wrapper {
    .product__thumb {
      width: 100% !important;
    }
  }
}

@media (max-width: 575px) {
  .custom-row-10 {
    width: 100% !important;
  }
  .shop_page_products_row {
    width: 50% !important;

    .product__wrapper {
      .product__thumb {
        width: 100% !important;
      }
    }
  }
}

@media (min-width: 576px) and (max-width: 991px) {
  .shop_page_products_row {
    width: 33.3% !important;

    .product__wrapper {
      .product__thumb {
        width: 100% !important;
      }
    }
  }
}

.images_small_modal {
  width: 33% !important;
}

.images_for_modal {
  background: #f5f5f5;
  border-radius: 4px;
  width: 100%;
  object-fit: contain;
}

.image_big_modal {
  object-fit: contain;
}

.iiz__img {
  object-fit: contain;
}

.image_big_modal {
  width: 98% !important;
  // margin-left: 20px !important;
  margin-top: -20px !important;
}

.banner_card {
  height: 350px;

  a {
    height: 100%;
    img {
      object-fit: cover;
    }
  }

  @media (max-width: 571px) {
    height: auto;
  }

  @media (min-width: 992px) and (max-width: 1290px) {
    height: 280px;
  }
}

.loader_div {
  background: rgb(0.2, 0.2, 0.2, 0.2);
  // backdrop-filter: blur(1px);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 999999;
}

.loader {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader::before {
  content: "";
  background: rgba(255, 255, 255, 0);
  backdrop-filter: blur(8px);
  position: absolute;
  width: 140px;
  height: 55px;
  z-index: 20;
  border-radius: 0 0 10px 10px;
  border: 1px solid rgba(255, 255, 255, 0.274);
  border-top: none;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.082);
  animation: anim2 2s infinite;
}

.loader div:nth-child(1) {
  // background: rgb(228, 228, 228);
  // background: #e31268;
  background: #bc8246;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  z-index: 1;
  animation: anim 2s infinite linear;
  animation-delay: calc(-0.3s * 1);
  transform: translateY(5px);
  margin: 0.2em;
}
.loader div:nth-child(2) {
  background: #bc8246;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  z-index: 1;
  animation: anim 2s infinite linear;
  animation-delay: calc(-0.3s * 2);
  transform: translateY(5px);
  margin: 0.2em;
}
.loader div:nth-child(3) {
  background: #bc8246;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  z-index: 1;
  animation: anim 2s infinite linear;
  animation-delay: calc(-0.3s * 3);
  transform: translateY(5px);
  margin: 0.2em;
}
.loader div:nth-child(4) {
  background: #bc8246;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  z-index: 1;
  animation: anim 2s infinite linear;
  animation-delay: calc(-0.3s * 4);
  transform: translateY(5px);
  margin: 0.2em;
}

@keyframes anim {
  0%,
  100% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(-65px);
  }
}

@keyframes anim2 {
  0%,
  100% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(10deg);
  }
}
