/*----------------------------------------*/
/*  02. HEADER CSS
/*----------------------------------------*/

/* logo */
.logo{
    @media #{$xs}{
        text-align: center;
    }
    &__6{
        @media #{$xs}{
            padding-bottom: 15px; 
        }
    }
}

.header{
    &__area{
        @media #{$xs}{
            padding-top: 15px;
        }
    }
    &__top{
        &-2{
            padding-top: 33px; 
            @media #{$xs}{
                padding-top: 0;  
            }
        }
    }
    &__transparent{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 99;
        background: transparent;
        &.transparent-sticky{
            position: static;
        }
        &-2{
            @media #{$md} {
                background: $white;
            }
        }
    }
    &__welcome{
        @media #{$sm}{
            padding-bottom: 20px;
            text-align: center;
        }
        @media #{$xs}{
            text-align: center;
            padding-bottom: 15px;    
        }
        & span{
            color: $black;
            
        }
    }
    &__right{}
    &__action{
        & ul{
            & li{
                display: inline-block;
                position: relative;
                margin-left: 25px;
                & > button{
                    color: $black-soft;
                    position: relative;
                    display: inline-block;
                    padding: 27px 0;
                    padding-bottom: 30px;
                    position: relative;
                    background: transparent;
                    &:last-child{
                        margin-right: 0;
                    }
                    & i{
                        color: $black-2;
                        margin-right: 3px;
                        font-size: 18px;
                    }
                    & .cart-number-2{
                        position: absolute;
                        top: 18px;
                        left: 15px;
                        text-align: center;
                        min-width: 20px;
                        font-size: 12px;
                        line-height: 14px;
                        padding: 3px;
                        border-radius: 90px;
                        background: $theme-2-color;
                        color: $white;
                    }
                }
                &:hover{
                    & > button{
                        color: $black-2;
                    }
                    & .mini-cart{
                        visibility: visible;
                        opacity: 1;
                    }
                    & .extra-info{
                        visibility: visible;
                        opacity: 1;
                    }
                }
                & .mini-cart{
                    position: absolute;
                    right: 0;
                    top: 100%;
                    background: $white;
                    padding: 25px;
                    width: 350px;
                    z-index: 9999999;
                    border: 1px solid #e1e1e1;
                    @include box-shadow(0px 15px 30px 0px rgba(0, 3, 143, 0.1));
                    visibility: hidden;
                    opacity: 0;
                    @include transition(.3s);
                    @media #{$xs}{
                        width: 280px;
                        right: -60%;
                    }
                    
                    & .mini-cart-list{
                        &.slider-height{
                          height: 350px;
                          overflow-y: scroll;
                        }
                        &.slider-height-2{
                          height: 250px;
                          overflow-y: scroll;
                        }
                        overflow: hidden;
                        @media #{$md}{
                            height: 200px;
                        }
                        @media #{$sm}{
                            height: 165px;
                        }
                        @media #{$xs}{
                            height: 165px;
                        }
                        & li{
                            display: block;
                            margin-bottom: 30px;
                            margin-left: 0;
                            overflow: hidden;
                            padding: 0;
                            & .cart-img{
                                margin-right: 15px;
                                & a{
                                    & img{
                                        width: 75px;
                                    }
                                }
                            }
                            & .cart-content{
                                margin-top: 5px;
                                & h5{
                                    font-size: 13px;
                                    font-weight: 500;
                                    & a{
                                        color: $black-soft;
                                        &:hover{
                                            color: $theme-color;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    & .total-price{
                        padding-top: 22px;
                        border-top: 1px solid $black;
                        & span{
                            font-weight: 500;
                            color: $black;
                        }
                    }
                    & .checkout-link{
                        & a{
                            margin-bottom: 20px;
                            width: 100%;
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }
                    }
                }
                & .extra-info{
                    position: absolute;
                    top: 100%;
                    right: 0;
                    background-color: $white;
                    padding: 20px;
                    margin: 0;
                    min-width: 220px;
                    padding-bottom: 5px;
                    border: 1px solid #e1e1e1;
                    z-index: 9;
                    max-height: 400px;
                    overflow: auto;
                    visibility: hidden;
                    opacity: 0;
                    @include transition(.3s);
                    & li{
                        display: block;
                        margin-left: 0;
                        margin-bottom: 10px;
                        & ul{
                            & li{
                                margin-bottom: 0;
                                & a{
                                    padding: 5px 0;
                                    line-height: 30px;
                                }
                            }
                        }
                    }
                    & .extra-title{
                        & h5{
                            font-size: 14px;
                            color: $black-2;
                            margin: 15px 0 10px;
                            text-transform: capitalize;
                        }
                    }
                }
            }
        }
        &.header__action-2{
            & ul{
                & li{
                    @media #{$xs}{
                        margin-left: 15px;
                    }
                }
            }
        }
    }
    &__search{
        position: fixed;
        top: -100px;
        left: 0;
        width: 100%;
        min-height: 330px;
        padding: 40px 0;
        visibility: hidden;
        opacity: 0;
        @include transform(scale(.5));
        z-index: 9999;
        @media #{$sm}{
            min-height: 300px;
        }
        @media #{$xs}{
            padding-top: 30px;
            min-height: 300px;
        }
        &.search-opened{
            top: 0;
            visibility: visible;
            opacity: 1;
            @include transform(scale(1));
        }
        &-btn{
            margin-bottom: 35px;
            @media #{$sm}{
                margin-bottom: 20px;
            }
            @media #{$xs}{
                margin-bottom: 20px;
            }
            &-close{
                font-size: 18px;
                color: $theme-color;
                background: transparent;
            }
        }
        &-header{
            margin-bottom: 35px;
            @media #{$sm}{
                margin-bottom: 20px;
            }
            @media #{$xs}{
                margin-bottom: 20px;
            }
            & h3{
                color: $black-2;
                font-size: 30px;
            }
        }
        &-categories{
            margin-bottom: 35px;
            & ul{
                & li{
                    display: inline-block;
                    & a{
                        font-size: 18px;
                        color: $black-2;
                        padding: 0 30px;
                        &:hover{
                            color: $theme-color;
                        }
                        &.active{
                            color: $theme-color;
                        }
                        @media #{$md}{
                            padding: 0 15px;
                        }
                        @media #{$sm}{
                            font-size: 16px;    
                            padding: 0 5px;
                        }
                        @media #{$xs}{
                            padding: 0 5px;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        &-input{
            & input{
                height: 50px;
                width: 100%;
                border: none;
                padding: 0 15px;
                padding-right: 30px;
                border-bottom: 1px solid $border;
                &::placeholder{
                    color: $black-soft;
                }
                &:focus{
                    border-bottom-color: $theme-color;
                }
            }
            & button{
                position: absolute;
                top: 0;
                right: 0;
                height: 50px;
                width: 50px;
                text-align: center;
                font-size: 14px;
                background: transparent;
                color: $black-2;
            }
        }
    }
}

.sticky {
	left: 0;
	margin: auto;
	position: fixed !important;
	top: 0;
	width: 100%;
	box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.07);
	z-index: 999;
	-webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
	animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
	-webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
    background: $white !important;
}

.main-menu{
    & ul{
        & li{
            display: inline-block;
            margin-right: 40px;
            position: relative;
            text-align: left;
            @media #{$lg}{
                margin-right: 30px;
            }
            & a{
                display: block;
                color: $black-soft;
                font-weight: 500;
                padding: 27px 0;
                padding-bottom: 30px;
                text-transform: capitalize;
            }
            &.active{
                & a{
                    color: $black-2;
                }
            }
            &.has-dropdown{
                & > a{
                    position: relative;
                    padding-right: 15px;
                    &::after{
                        position: absolute;
                        content: '\f107';
                        right: 0;
                        top: 50%;
                        @include transform(translateY(-50%));
                        font-family: "Font Awesome 5 Pro";
                        font-size: 14px;
                    }
                }
            }
            &:hover{
                & a{
                    color: $black-2;
                }
                & ul.submenu{
                    visibility: visible;
                    opacity: 1;
                    @include transform(scaleY(1));
                }
            }
            & ul.submenu{
                position: absolute;
                top: 100%;
                left: 0;
                background-color: $white;
                min-width: 220px;
                padding: 15px 0;
                @include transform(scaleY(0));
                transform-origin: 0 0;
                @include box-shadow(0px 15px 30px 0px rgba(0, 3, 143, 0.1));
                opacity: 0;
                visibility: hidden;
                z-index: 11;
                & li{
                    margin: 0;
                    display: block;
                    &.active{
                        & a{
                            color: $black-2;
                        }
                    }
                    & a{
                        padding: 5px 20px;
                        color: $black-soft;
                        &:hover{
                            color: $black-2;
                        }
                    }
                }
            }
            &.mega-menu{
                position: static;
                & ul.submenu{
                    background-repeat: no-repeat;
                    background-size: auto;
                    background-position: center right;
                    background-attachment: scroll;
                    width: 100%;
                    padding: 40px 35px;
                    & li{
                        float: left;
                        display: block;
                        width: 25%;
                        & a{
                            color: $black-2;
                            margin-bottom: 20px;
                            padding: 0;
                            &::after{
                                display: none;
                            }
                        }
                        & ul{
                            
                            & li{
                                float: none;
                                width: 100%;
                                padding-left: 0;
                                & a{
                                    font-size: 12px;
                                    color: $black-soft;
                                    margin-bottom: 0;
                                    padding-bottom: 12px;
                                    &:hover{
                                        color: $black-2;
                                    }
                                }
                                &:last-child{
                                    & a{
                                        padding-bottom: 0;
                                    }
                                }
                            }
                        }
                    }
                }
                &.mega-menu-2{
                    & ul.submenu{
                        @media #{$lg}{
                            background: none;
                        }
                        & > li{
                            @media #{$lg}{
                                width: 33.33%;
                            }
                        }
                    }
                }
            }
        }
    }
    &-2{
        & ul{
            & li{
                margin: 0 20px;
                @media #{$lg}{
                    margin: 0 15px;
                }
            }
        }
    }
    &-3{
        & ul{
            & li{
                &:hover > a{
                    color: $theme-2-color;
                }
                & ul.submenu li:hover a {
                    color: $theme-2-color;
                }
                // & .mega-menu ul.submenu li ul li:hover a {
                //     color: $theme-2-color;
                // }
                & .mega-menu ul.submenu li ul li a:hover {
                    color: $theme-2-color;
                }
            }
        }
    }
}

/* mobile menu*/
.mobile-menu-btn{
    & button{
        font-size: 18px;
        color: $black-2;
        background: transparent;
    }
}



.body-overlay{
    background-color: rgba($color: #000000, $alpha: .5);
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 999;
	left: 0;
	opacity: 0;
    visibility: hidden;
}
.body-overlay.opened{
	opacity: 1;
    visibility: visible;
}


.extra__info{
    &-close{
        & a{
            color: $black-2;
            font-size: 18px;
            &:hover{
                color: $theme-color;
            }
        }
    }
    &-inner{
        position: fixed;
        right: -370px;
        top: 0;
        padding: 30px;
        width: 350px;
        height: 100%;
        background: $white;
        -webkit-transition: all .3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        -moz-transition: all .3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        transition: all .3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        @include box-shadow(-5px 0 20px -5px rgba(0, 0, 0, 0.5));
        z-index: 9999;
    }
    &-logo{
        padding: 30px;
        
    }
    &-content{
        margin-bottom: 30px;
        & h4{
            color: $white;
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 20px;
            text-transform: capitalize;
        }
        & p{
            color: $white;
            margin-bottom: 25px;
        }

        & ul{
            & li{
                margin-bottom: 10px;
                display: flex;
                & .icon{
                    & i{
                        line-height: 27px;
                        color: $white;
                        margin-right: 15px;
                    }
                }
                & .text{
                    & span{
                        color: $white;
                    }
                }
            }
        }
    }
    &.info-opened{
        & .extra__info-inner{
            right: -20px;
        }
    }
}

/* side menu area start */

.side-mobile-menu {
	display: none;
	& ul {
		list-style: none;
		margin: 0;
		padding: 0;

		& li {
			display: block;
			&:last-child{
				& a{
					border-bottom: none;
				}
			}
			& > a {
				padding: 13px 0;
				display: block;
				border-bottom: 1px solid rgba($color: $black-2, $alpha: .5);
				font-size: 16px;
				color: $black-2;
				font-weight: 500;
				text-transform: uppercase;
				position: relative;

				&:hover {
					color: $theme-color;
					padding-left: 5px;
				}
			}

			& a[aria-expanded="true"] {
				color: $black-2;

				&:before {
					background: $black-2;
					border-color: $black-2;
				}
			}

			& ul {
				padding-left: 0;
				list-style: none;

				& li {
					padding-left: 15px;

					&:hover {
						> a {
							color: $theme-color;
							padding-left: 20px;

							&:before {
								background: $black-2;
								border-color: $black-2;
							}
						}
					}

					& a {
						position: relative;
						padding-left: 15px;
						text-transform: capitalize;
						font-size: 16px;

						&:before {
							content: "";
							width: 8px;
							height: 8px;
							position: absolute;
							left: 0;
							top: 50%;
							transform: translateY(-50%);
							border: 2px solid $black;
							border-radius: 50%;
						}
					}

					& a[aria-expanded="true"] {
						padding-left: 20px;
					}
				}
			}
		}

		& li.has-dropdown {
			& > a {
				&:after {
					position: absolute;
					content: "";
					width: 8px;
					height: 8px;
					border-width: 2px 0 0 2px;
					border-style: solid;
					border-color: initial;
					right: 16px;
					top: 50%;
					-webkit-transform: rotate(-45deg) translateY(-50%);
					transform: rotate(-45deg) translateY(-50%);
					-webkit-transform-origin: top;
					transform-origin: top;
					transition: all .3s ease-out;
				}
			}

			& a[aria-expanded="true"] {
				&:after {
					-webkit-transform: rotate(-135deg) translateY(-50%);
					transform: rotate(-135deg) translateY(-50%);
				}
			}
		}
	}
}

.del-icon button{
    background: transparent;
}