

.profile{
    &__basic{
        &-inner{
            border-bottom: 1px solid #eceaea;
            padding: 15px 30px;
            padding-right: 40px;
        }
        &-thumb{
            @media #{$sm, $xs}{
                margin-bottom: 20px;
            }
            & img{
                width: 70px;
                height: 70px;
                @include border-radius(50%);
                border: 1px solid #e4e4e4;
            }
        }
        &-title{
            font-size: 22px;
            color: #000;
            font-weight: 400;
            margin-bottom: 5px;
            & span{
                font-weight: 600;
            }
        }
        &-content{
            & p{
                font-size: 14px;
                font-weight: 400;
                color: #000;
                margin-bottom: 0;
                & a{
                    font-weight: 500;
                    color: #bc8246;
                }
            }
        }
        &-cart{
            @media #{$sm, $xs}{
                margin-top: 25px;
            }
            & .cart-info{
                & a{
                    font-size: 16px;
                    color: #000;
                    text-transform: capitalize;
                    font-weight: 500;
                    &:hover{
                        color: #bc8246;
                    }
                }
            }
            & .cart-item{
                & a{
                    position: relative;
                    font-size: 18px;
                    color: #000;
                    &:hover{
                        color: #bc8246;
                    }
                    
                }
            }
            & .cart-quantity{
                position: absolute;
                bottom: -4px;
                right: -7px;
                font-size: 10px;
                display: inline-block;
                width: 17px;
                height: 17px;
                text-align: center;
                line-height: 17px;
                background-color: #bc8246;
                color: #fff;
                font-weight: 500;
                @include border-radius(50%);

            }
        }
    }
    &__menu{
        &-left{
            padding: 26px 20px 5px;
            border: 1px solid #eceaea;
        }
        &-title{
            font-size: 14px;
            font-weight: 600;
            color: #bc8246;
            border-bottom: 1px solid #eceaea;
            margin-bottom: 0;
            padding-bottom: 20px;
            & i{
                width: 10px;
                margin-right: 10px;
                display: inline-block;
            }

        }
        &-tab{
            & .nav-tabs{
                padding: 0;
                margin: 0;
                border: 0;
                & .nav-link{
                    padding: 0;
                    margin: 0;
                    border: 0;
                    font-size: 14px;
                    font-weight: 500;
                    color: #000;
                    text-align: left;
                    padding: 15px 0;
                    background-color: transparent;
                    transition: all 0.3s ease-out 0s;
                    &:not(:last-child){
                        border-bottom: 1px solid #e4e4e4;
                    }
                    &.active{
                        transition: all 0.3s ease-out 0s;
                    }
                    &.active,
                    &:hover{
                        color: #bc8246;
                    }
                    & i{
                        margin-right: 10px;
                        width: 10px;
                        display: inline-block;
                       
                    }
                }
            }
        }
    }
    &__info{
        &-top{
            padding: 20px 70px;
            background-color: #fff;
            margin-bottom: 15px;

            @media #{$xs}{
                padding-left: 15px;
                padding-right: 15px;
            }
        }
        &-btn{
            font-size: 14px;
            color: #000;
            font-weight: 500;
            background-color: transparent;
            & i{
                margin-right: 5px;
            }
            &:hover{
                color: #bc8246;
            }
        }
        &-title{
            font-size: 18px;
            font-weight: 600;
            color: #000;
            margin-bottom: 0;
            & i{
                width: 10px;
                margin-right: 10px;
                display: inline-block;
            }

        }
        &-wrapper{
            padding: 0 70px;

            @media #{$xs}{
                padding-left: 15px;
                padding-right: 15px;
            }
        }
        &-item{
            padding: 30px 0;
            &:not(:last-child){
                border-bottom: 1px solid #eceaea;
            }
            & p{
                font-size: 14px;
                text-transform: capitalize;
                color: #000;
                margin-bottom: 10px;
                line-height: 1;
            }
            & h4{
                font-size: 16px;
                line-height: 1;
                margin-bottom: 0;
            }
        }
    }
    &__edit{
        &-wrapper{
            padding: 40px 60px;
            @media #{$xs}{
                padding-left: 15px;
                padding-right: 15px;
            }
        }
        &-input{
            margin-bottom: 25px;
            & p{
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 0;
                color: #000;
            }
            & input{
                width: 100%;
                height: 60px;
                line-height: 56px;
                background: var(--tp-grey-2);
                border: 1px solid #ececec;
                @include border-radius(4px);
                padding-left: 30px;
                padding-right: 45px;
                &::placeholder{
                    color: #8d8e9a;
                    font-weight: 500;
                }
                &:focus{
                    border-color: #bc8246;
                    background-color: #fff;
                }
            }
        }
        &-close{
            position: absolute;
            top: 15px;
            right: 15px;
            &-btn{
                font-size: 15px;
                color: #6f7172;
                height: 30px;
                width: 30px;
                line-height: 30px;
                text-align: center;
                border: 1px solid #e4e4e4;

                &:hover{
                    color: #fff;
                    background-color: #bc8246;
                    border-color: #bc8246;
                }
            }
        }
    }
}

.order{
    &__info{
        &-top{
            padding: 20px 70px;
            background-color: #fff;
            margin-bottom: 15px;
            @media #{$xs}{
                padding-left: 15px;
                padding-right: 15px;
            }
        }
        &-btn{
            font-size: 14px;
            color: #000;
            font-weight: 500;
            background-color: transparent;
            & i{
                margin-right: 5px;
            }
            &:hover{
                color: #bc8246;
            }
        }
        &-title{
            font-size: 18px;
            font-weight: 600;
            color: #000;
            margin-bottom: 0;
            & i{
                width: 10px;
                margin-right: 10px;
                display: inline-block;
            }

        }
        &-wrapper{
            padding: 0 70px;
        }
        &-item{
            padding: 30px 0;
            &:not(:last-child){
                border-bottom: 1px solid #eceaea;
            }
            & p{
                font-size: 14px;
                text-transform: capitalize;
                color: #000;
                margin-bottom: 10px;
                line-height: 1;
            }
            & h4{
                font-size: 16px;
                line-height: 1;
                margin-bottom: 0;
            }
        }
    }
    &__list{
        padding: 35px 70px 5px;
        @media #{$xs}{
            padding-left: 15px;
            padding-right: 15px;
        }
        & .table{
            @media #{$md, $sm, $xs}{
                width: 600px;
            }
        }
        & table{
            border: 0;
            
            & th{
                border-bottom: 1px solid #e4e4e4;
                font-size: 14px;
                text-transform: uppercase;
                font-weight: 600;
                & td{
                    padding-left: 0;
                    padding-right: 0;
                }
            }
            & tr{
                &:not(:last-child){
                    border-bottom: 1px solid #e4e4e4;
                }
            }
            & > :not(:first-child){
                border-top: 1px solid #e4e4e4;
            }
        }
        & .table > :not(caption) > * > *{
            border: 0;
            padding-left: 0;
            padding-right: 0;
        }

        & tbody{
            & tr{
                & td{
                    padding: 20px 0;
                }
            }
        }
    }
    &__id{
        color: #bc8246;
        font-weight: 500;

    }
    &__view{
        &-btn{
            font-weight: 500;
            color: #000;

            &:hover{
                color: #bc8246;
            }
        }
    }
    &__title{
        &:hover{
            color: #bc8246;
        }
    }
}

.password{
    &__change{
        &-top{
            padding: 20px 70px;
            background-color: #fff;
            margin-bottom: 15px;
            @media #{$xs}{
                padding-left: 15px;
                padding-right: 15px;
            }
        }
        &-btn{
            font-size: 14px;
            color: #000;
            font-weight: 500;
            & i{
                margin-right: 5px;
            }
            &:hover{
                color: #bc8246;
            }
        }
        &-title{
            font-size: 18px;
            font-weight: 600;
            color: #000;
            margin-bottom: 0;
            & i{
                width: 10px;
                margin-right: 10px;
                display: inline-block;
            }

        }
        &-wrapper{
            padding: 0 70px;
            @media #{$xs}{
                padding-left: 15px;
                padding-right: 15px;
            }
        }
        &-item{
            padding: 30px 0;
            &:not(:last-child){
                border-bottom: 1px solid #eceaea;
            }
            & p{
                font-size: 14px;
                text-transform: capitalize;
                color: #000;
                margin-bottom: 10px;
                line-height: 1;
            }
            & h4{
                font-size: 16px;
                line-height: 1;
                margin-bottom: 0;
            }
        }
    }
    &__form{
        padding: 35px 70px;
        @media #{$xs}{
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    &__input{
        margin-bottom: 25px;
        & p{
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 0;
            color: #000;
        }
        & input{
            width: 100%;
            height: 60px;
            line-height: 56px;
            background: var(--tp-grey-2);
            border: 1px solid #ececec;
            @include border-radius(4px);
            padding-left: 30px;
            padding-right: 45px;
            &::placeholder{
                color: #8d8e9a;
                font-weight: 500;
            }
            &:focus{
                border-color: #bc8246;
                background-color: #fff;
            }
        }
    }
}
