/*----------------------------------------*/
/*  12. CONTACT CSS START
/*----------------------------------------*/

.contact{
    &__area{}
    &__info{
        & h3{
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 52px;
        }
        & p{
            margin-bottom: 55px;
        }
        & ul{
            & li{
                &:hover{
                    & .contact__info-icon{
                        & i{
                            border-color: $black-2;
                            background: $black-2;
                            color: $white;
                        }
                    }
                }
            }
        }
        &-icon{
            & i{
                font-size: 18px;
                color: #6c6c6c;
                display: inline-block;
                width: 53px;
                height: 53px;
                line-height: 49px;
                text-align: center;
                border: 2px solid $border;
            }
        }
        &-content{
            & h6{
                font-size: 14px;
                margin-bottom: 2px;
            }
            & span{
                color: $black-soft-2;
            }
        }
    }
    &__social{
        & ul{
            & li{
                display: inline-block;
                margin-right: 42px;
                & a{
                    font-size: 18px;
                    color: $black-soft-2;
                    &:hover{
                        color: $theme-color;
                    }
                }
            }
        }
    }
    &__form{
        @media #{$md}{
            margin-top: 50px;
        }
        @media #{$sm}{
            margin-top: 50px;
        }
        @media #{$xs}{
            margin-top: 50px;
        }
        & h3{
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 45px;
        }
    }
    &__input{
        & label{
            color: #333333;
            display: inline-block;
            margin-bottom: 10px;
            & span.required{
                color: red;
            }
        }

        & input,
        & textarea{
            height: 50px;
            width: 100%;
            border: 1px solid #ececec;
            padding: 0 15px;
            font-size: 14px;
            // margin-bottom: 22px;
            &:focus{
                border-color: $theme-color;
            }
        }
        & textarea{
            height: 180px;
            resize: none;
            margin-bottom: 45px;
        }
    }
    &__map{
        &-wrapper{
            height: 590px;
            & iframe{
                position: absolute;
                width: 100%;
                height: 100%;
                border: none;
            }
        }
    }
}
.contact__input{
    margin-bottom: 22px;
}