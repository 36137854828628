/*----------------------------------------*/
/*  06. CLIENT CSS START
/*----------------------------------------*/


.client{
    &__area{}
    &__slider{}
    &__thumb{
        & img{
            width: inherit !important;
        }
    }
}