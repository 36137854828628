/*----------------------------------------*/
/*  08. SUBSCRIBE CSS START
/*----------------------------------------*/

.subscribe{
    &__inner{
        border-top: 1px solid $border;
        &-2{
            border: none;
        }
    }
    &__content{
        & h2{
            font-weight: 400;
            margin-bottom: 15px;
        }
        & p{
            padding: 0 60px;
            margin-bottom: 53px;
            @media #{$xs}{
                padding: 0;
            }
        }
    }
    &__form{
        & input{
            width: 100%;
            height: 50px;
            line-height: 50px;
            padding: 0 20px;
            color: $black;
            font-size: 14px;
            border: none;
            border-bottom: 1px solid #c1c1c1;
            text-align: center;
            margin-bottom: 40px;
            background: transparent;
            &:focus{
                border-color: $theme-color;
            }
            &::placeholder{
                color: #8c8c8c;
            }
        }
    }
}